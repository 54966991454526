// extracted by mini-css-extract-plugin
export var backHomeBtn = "_404-module--backHomeBtn--ExXat";
export var base = "_404-module--base--dsHg4";
export var blockQuote = "_404-module--blockQuote--VNd1o";
export var description = "_404-module--description--cBnyP typography-module--responsiveTitle4--z-i93";
export var large = "_404-module--large--2C81e";
export var micro = "_404-module--micro--tNbND";
export var paragraph = "_404-module--paragraph--VB-7i";
export var responsiveTitle1 = "_404-module--responsiveTitle1--s4QU8";
export var responsiveTitle2 = "_404-module--responsiveTitle2--ge9jy";
export var responsiveTitle3 = "_404-module--responsiveTitle3--d-Jjf";
export var responsiveTitle4 = "_404-module--responsiveTitle4--VZNEe";
export var responsiveTitle5 = "_404-module--responsiveTitle5--W-uiH";
export var responsiveTitleHuge = "_404-module--responsiveTitleHuge--3XI7I";
export var small = "_404-module--small--BTfWv";
export var title = "_404-module--title--Zajmy typography-module--responsiveTitle1--ZOeXB";
export var title1 = "_404-module--title1--Dtd+c";
export var title2 = "_404-module--title2--6A95w";
export var title3 = "_404-module--title3--e1NJD";