import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import Container from '../components/layout/container';
import Section from '../components/sections/section';
import { button as buttonCss } from '../styles/buttons.module.css';
import { bgHeader } from '../components/layout/layout.module.css';
import { title, description, backHomeBtn } from './404.module.css';
import { Link } from 'gatsby';
import { cn } from '../lib/helpers';

const NotFoundPage = () => {
  return (
    <>
      <div className={bgHeader} />
      <SEO title="404: Not found" />
      <Section color="dark">
        <Container narrow>
          <h1 className={title}>404</h1>
          <h4 className={description}>Page not available. Please return home.</h4>
          <Link to="/" className={cn(buttonCss, backHomeBtn)}>
            Back to Home
          </Link>
        </Container>
      </Section>
    </>
  );
};

export default NotFoundPage;
